"use client"

import {
  Box,
  Link,
  Spacer,
  IconButton,
  Button,
  VStack,
  Heading,
  Divider,
  Text,
} from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import useMemberStore from "@/store/MemberStore"
const lang = require("@/messages/HeaderComponent.json")

export default function MobileHeaderComponent({ locale }) {
  const t = lang[locale]
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { member } = useMemberStore()
  const isLoggedIn = useMemberStore((state) => state.checkIsLoggedIn())
  const btnRef = useRef()

  const dashboardUrl =
    member && member.connectedThreadsAccounts.length > 0
      ? `/dashboard/${member.connectedThreadsAccounts[0].username}`
      : `/connectThreads`
  const memberSection = (
    <VStack spacing="6px" align="left" marginTop="16px">
      <Heading size="md">{t["Title"]["memberFeature"]}</Heading>
      <Divider />
      <Link href={dashboardUrl}>
        <Text color="text.dark" fontSize="18px">
          {t["Title"]["dashboard"]}
        </Text>
      </Link>
    </VStack>
  )

  return (
    <Box
      height="68px"
      background="#302F2F"
      width="100%"
      display="flex"
      alignItems="center">
      <Link
        href="/"
        color="main.red"
        marginLeft="16px"
        fontSize="26px"
        fontWeight="bold">
        Threadslytics
      </Link>
      <Spacer />
      <IconButton
        ref={btnRef}
        onClick={onOpen}
        marginRight="16px"
        icon={<HamburgerIcon />}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            <VStack align="left" spacing="6px" marginTop="10px">
              {isLoggedIn && memberSection}
              <VStack spacing="6px" align="left" marginTop="16px">
                <Heading size="md">Threadslytics</Heading>
                <Divider />
              </VStack>
              <Link href="/pricing">
                <Text color="text.dark" fontSize="18px">
                  {t["Title"]["pricing"]}
                </Text>
              </Link>
              {locale == "zh-TW" && (
                <Link href="/leaderboard">
                  <Text color="text.dark" fontSize="18px">
                    {t["Title"]["leaderboard"]}
                  </Text>
                </Link>
              )}
              <Link href="/dashboard/netflixtw">
                <Text color="text.dark" fontSize="18px">
                  {t["Title"]["demoFeature"]}
                  <ExternalLinkIcon />
                </Text>
              </Link>
              <VStack spacing="6px" align="left" marginTop="16px">
                <Heading size="md">{t["Title"]["contactUs"]}</Heading>
                <Divider />
              </VStack>
              <Link href="https://threads.net/@threadslytics">
                <Text color="text.dark" fontSize="18px">
                  {t["Title"]["contactUsThread"]}
                </Text>
              </Link>
              <Link href="https://instagram.com/@threadslytics">
                <Text color="text.dark" fontSize="18px">
                  {t["Title"]["contactUsInstagram"]}
                </Text>
              </Link>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t["Title"]["close"]}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
