import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    components: {
        Link: {
            baseStyle: {
                color: 'rgb(44, 131, 219)'
            }
        },
        Select: {
            variants: {
                outline: {
                    field: {
                        borderColor: "ui.separator",
                    },
                },
            },
        },
        Textarea: {
            variants: {
                outline: {
                    borderColor: "ui.separator",
                },
            },
        },
        Input: {
            baseStyle: {
                borderColor: "red",
            },
            variants: {
                outline: {
                    field: {
                        borderColor: "ui.separator",
                    },
                },
            }
        },
        Tabs: {
            baseStyle: {
                tab: {
                    _selected: {
                        color: "white",
                        bg: "main.blue",
                    },
                },
            },
        },
    },
    breakpoints: {
        base: "0px",
        lg: "800px",
    },
    fonts: {
        kanit: `'Kanit', sans-serif`,
        space: `'Space Grotesk', sans-serif`,
    },
    colors: {
        main: {
            blue: 'rgb(44, 131, 219)',
            red: 'rgb(249, 187, 187)'
        },
        ui: {
            foreground1: 'rgb(255, 255, 255)',
            foreground2: 'rgb(250, 251, 252)',
            background: 'rgb(242, 243, 244)',
            separator: 'rgba(0, 0, 0, 0.15)'
        },
        text: {
            dark: 'rgb(0, 0, 0)',
            semidark: 'rgba(0, 0, 0, 0.75)',
            semilight: 'rgba(0, 0, 0, 0.35)',
            black: 'rgb(0, 0, 0)',
            white: 'rgb(255, 255, 255)',
            default: 'rgba(0, 0, 0, 0.5)'
        },
        ansi: {
            redLight: 'rgb(255, 102, 102)',
            red: 'rgb(153, 0, 0)',
            yellowLight: 'rgb(255, 255, 102)',
            yellow: 'rgb(153, 153, 0)',
            greenLight: 'rgb(102, 255, 102)',
            green: 'rgb(0, 153, 0)',
        }
    },
    // https://github.com/chakra-ui/chakra-ui/discussions/5048
    styles: {
        global: (props) => ({
            a: {
                color: "rgb(44, 131, 219)", // blue
            },
            body: {
                bg: "rgb(255, 255, 255)",
            },
        })
    },
})

export default theme
