import axios from 'axios'
import Cookies from 'js-cookie'

const apiHelper = (context) => {
    const cookieToken = Cookies.get('access_token')
    let headers = {
        'Content-Type': 'application/json',
    }
    if (cookieToken) {
        headers['Authorization'] = `Bearer ${cookieToken}`
    } else if (context && context.req != null) {
        const req = context.req
        const token = req.headers.cookie ? parseCookie(req.headers.cookie, 'access_token') || null : null;
        headers['Authorization'] = `Bearer ${token}`
    }

    return axios.create({
        baseURL: process.env.NEXT_PUBLIC_API_URL,
        headers
    })
}

const parseCookie = (cookieString: string, cookieName: string) => {
    const cookies = cookieString.split('; ')

    for (const cookie of cookies) {
        const [name, value] = cookie.split('=')
        if (name === cookieName) {
            return value;
        }
    }

    return null; // Cookie not found
};

export default apiHelper